import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 路由重写防止跳转相同路由控制台报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "index",
    component: () => import("../views/index"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index"),
        meta: {
          title: "首页-讯音科技",
        },
      },
      {
        path: "/sms",
        name: "sms",
        component: () => import("../views/sms/index"),
        meta: {
          title: "产品-短信服务",
        },
      },
      {
        path: "/voice",
        name: "voice",
        component: () => import("../views/voice/index"),
        meta: {
          title: "产品-语音服务",
        },
      },
      {
        path: "/addVal",
        name: "addVal",
        component: () => import("../views/addVal/index"),
        meta: {
          title: "产品-增值服务",
        },
      },
      {
        path: "/media",
        name: "media",
        component: () => import("../views/media/index"),
        meta: {
          title: "产品-多媒体短信",
        },
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("../views/aboutUs/index"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("../views/contactUs/index"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/joinUs",
        name: "joinUs",
        component: () => import("../views/joinUs/index"),
        meta: {
          title: "加入我们",
        },
      },
      {
        path: "/retail",
        name: "retail",
        component: () => import("../views/field/retail.vue"),
        meta: {
          title: "零售行业",
        },
      },
      {
        path: "/realEstate",
        name: "realEstate",
        component: () => import("../views/field/realEstate.vue"),
        meta: {
          title: "地产行业",
        },
      },
      {
        path: "/game",
        name: "game",
        component: () => import("../views/field/game.vue"),
        meta: {
          title: "游戏行业",
        },
      },
      {
        path: "/financial",
        name: "financial",
        component: () => import("../views/field/financial.vue"),
        meta: {
          title: "金融行业",
        },
      },
      {
        path: "/electricity",
        name: "electricity",
        component: () => import("../views/field/electricity.vue"),
        meta: {
          title: "电商行业",
        },
      },
      {
        path: "/education",
        name: "education",
        component: () => import("../views/field/education.vue"),
        meta: {
          title: "教育行业",
        },
      },
      {
        path: "/dining",
        name: "dining",
        component: () => import("../views/field/dining.vue"),
        meta: {
          title: "餐饮行业",
        },
      },
    ],
  },
];
const router = new VueRouter({
  // mode:'history',
  routes,
  scrollBehavior: () => ({ y: 0 }), // 解决vue页面之间跳转，页面不是在顶部的问题
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
    next();
  }
});
export default router;
