import Vue from 'vue'
// import '@/utils/rem'
import './assets/style/public.css'
// import './assets/style/mobile.css'
import './assets/style/reset.css'


// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component("ElCollapseTransition", CollapseTransition);
import {
  Badge,
  Form,
  FormItem,
  Row,
  Col,
  Collapse,
  CollapseItem,
  Progress,
  Input,
  Dialog
} from "element-ui";
Vue.use(Badge);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Progress);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);



// ====================================
// 滚动加载动画
import "wow.js/css/libs/animate.css";
// ====================================
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import mixin from './mixins'

// ====================================注册全局组件
import Btn from "./components/btn.vue";
// import bannerText from "./components/bannerText.vue";
Vue.component("MyBtn", Btn);
// Vue.component("MyBannerText", bannerText);
// =======================================
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);
// =======================================
// import highlightPlugin from "@highlightjs/vue-plugin";
// import 'highlight.js/styles/dark.css'; // 引入内置样式
// import hljs from 'highlight.js/lib/common';
// import vuePlugin from "@highlightjs/vue-plugin";
// Vue.use(highlightPlugin);
// import VueTypedJs from 'vue-typed-js'
// Vue.use(vuePlugin)
// Vue.use(VueTypedJs)
// =======================================
import $ from 'jquery'
Vue.prototype.$ = $
// =======================================EventBus
Vue.prototype.$EventBus = new Vue();
// =======================================
Vue.mixin(mixin)
// 客服组件
import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
Vue.use(LemonIMUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
