<template>
  <div class="btn" :style="[{ background: configObj.bgcolor }]" @click="ljzx">
    {{ configObj.text || "立即咨询" }}
    <img :src="configObj.img || require('@/assets/icon/按钮蓝色箭头.svg')" />
  </div>
</template>

<script>
export default {
  props: {
    configObj: {
      type: Object,
      default(row) {
        return {
          text: row.text,
          bgcolor: row.bgcolor,
          color: row.color,
          //   width: row.width,
          //   height: row.height,
          center: row.center,
          img: row.img,
          //   padding: row.padding,
          isImg: row.isImg,
        };
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    ljzx() {
      this.$EventBus.$emit("consultingType", 1);
    },
  },
  computed: {},
  components: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 129px;
  height: 55px;
  // line-height: 55px;
  // text-align: center;
  padding: 15px;
  // margin-top: 40px;
  background: #ffffff;
  border-radius: 2px;
  font-family: "PingFangSC-Regular";
  font-size: 16px;
  color: #2a3bf5;
  letter-spacing: 0.96px;
  font-weight: 400;
  border: 1px solid #2a3bf5;
  border-radius: 6px;
  cursor: pointer;
  img {
    position: absolute;
    width: 12px;
    right: 4%;
    top: 10%;
  }
}
</style>
