let mixin = {
    created() {
     
    },
    methods: {
      MXchange(i){
        this.Index=i;
      }
    }
  }
   
  export default mixin